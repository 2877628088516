import { DataAccess } from "../dataaccess/data.access";
import { Timeout } from "../decorators/timeout.decorator";
import { UserprefService } from "./userpref.service";
import { S25ImageService } from "../modules/s25-image/s25.image.service";
import { Cache, Invalidate } from "../decorators/cache.decorator";

export interface Building {
    id?: number;
    buildingName: string;
    buildingCode?: string;
    latitude?: number | null;
    longitude?: number | null;
    description?: string;
    address?: string;
    city?: string;
    state?: string;
    zipPostCode?: string;
    country?: string;
    imageId?: number;
}

export class BuildingService {
    @Timeout
    @Cache({ immutable: true, targetName: "BuildingService" })
    public static getBuildings() {
        return DataAccess.get(
            DataAccess.injectCaller("/micro/building/list.json?", "BuildingService.getBuildings"),
        ).then(function (data) {
            return data && data.content.data;
        });
    }

    @Timeout
    @Cache({ immutable: true, targetName: "BuildingService" })
    public static getBuildingById(id: number) {
        //GET to micro/building requires login
        return UserprefService.getLoggedIn().then((loggedIn) => {
            if (loggedIn) {
                return DataAccess.get(
                    DataAccess.injectCaller(
                        "/micro/building/" + id + "/detail.json?",
                        "BuildingService.getBuildingById",
                    ),
                ).then(function (data) {
                    return data && data.content.data.items;
                });
            } else {
                return [];
            }
        });
    }

    @Timeout
    public static getBuildingByName(name: string) {
        return DataAccess.get(
            DataAccess.injectCaller(
                "/micro/building/list.json?buildingName=" + name,
                "BuildingService.getBuildingByName",
            ),
        ).then(function (data) {
            return data && data.content.data;
        });
    }

    @Timeout
    public static async getImageUrl(id: number): Promise<{ imageId: number; url: string }> {
        const buildings = await BuildingService.getBuildingById(id);
        const building = buildings[0];
        return {
            imageId: building?.imageId,
            url: building?.imageId && S25ImageService.getUrl(building.imageId),
        };
    }

    public static postBuilding(payload: any, bulk?: boolean) {
        return DataAccess.post(DataAccess.injectCaller("/micro/building/new.json", "BuildingService.postNewBuilding"), {
            content: {
                apiVersion: "0.1",
                data: bulk ? payload : [payload],
            },
        });
    }

    @Invalidate({ serviceName: "BuildingService" })
    @Timeout
    public static putBuilding(ids: any, payload: any) {
        return DataAccess.put(
            DataAccess.injectCaller("/micro/building/" + ids + "/detail.json", "BuildingService.postNewBuilding"),
            {
                content: {
                    apiVersion: "0.1",
                    data: [payload],
                },
            },
        );
    }

    // ids =4+5+6;
    public static deleteBuilding(ids: any) {
        return DataAccess.delete(
            DataAccess.injectCaller("/micro/building/" + ids + "/list.json?", "BuildingService.deleteBuilding"),
        ).then(function (data) {
            return data && data.content.data;
        });
    }
}
