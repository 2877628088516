//@author travis
import { DataAccess } from "../dataaccess/data.access";
import { S25Const } from "../util/s25-const";
import { S25Util } from "../util/s25-util";
import { EmailScenario } from "../modules/bpe/bpe.service";
import { ScheduledEmail } from "./scheduled.email.service";
import { Proto } from "../pojo/Proto";
import NumericalString = Proto.NumericalString;
import { Doc } from "../modules/system-settings/document-management/s25.document.const";
import { LooseAutocomplete } from "../pojo/Util";
import { Report } from "../pojo/Report";

export interface EmailDoc {
    email: {
        mail: {
            from?: string;
            subject?: string;
            body?: {
                text?: string;
            };
            recipient?: RecipientDoc[];
            attachment?: EmailAttachment[];
            template_id?: number;
        };
    };
}

export interface RecipientDoc {
    to?: string;
    cc?: string;
    bcc?: string;
}

export type EmailReport = {
    fromTemplate: boolean;
    rpt_engine: LooseAutocomplete<"WS" | "JR" | "DM">;
    rpt_id: number | NumericalString;
    itemID: number | NumericalString;
    isString: boolean;
    objString: string;
    template_id?: number;
    format?: number;
    params?: Report.Context;
};

export type EmailAttachment = {
    report?: string | number;
    report_run?: Report.Context;
    item?: number | NumericalString;
    result_type?: number;
    filename?: string;
    file_upload?: string;
    file_upload_name?: string;
};

export type EmailClass = {
    subject: string;
    body: string;
    to: string | string[];
    from: string;
    cc: string | string[];
    bcc: string | string[];
    reports: string | string[] | number[] | EmailReport[];
    iCalFile?: NumericalString;
};

export type TodoClass = {
    taskName: string;
    assignBy: string;
    assignTo: string;
    dueDate: string;
    comment: string;
};

export class EmailService {
    public static sendEmailFromClass(
        emailClass: EmailClass,
        itemId: number,
        itemTypeId: number,
        reportFls: boolean = true,
        webServiceFiles?: string[],
        templateId?: number,
        scenario?: EmailScenario,
    ) {
        if (!reportFls) {
            emailClass.reports = [];
        }
        return EmailService.sendEmail(
            S25Util.unescapeXml(emailClass.subject),
            emailClass.body,
            emailClass.to,
            emailClass.from,
            emailClass.cc,
            emailClass.bcc,
            emailClass.reports,
            webServiceFiles,
            null,
            itemId,
            itemTypeId,
            templateId,
            scenario,
        );
    }

    public static async sendEmail(
        subject: string,
        body: string,
        tos: string | string[],
        from: string,
        ccs?: string | string[],
        bccs?: string | string[],
        reports?: string | string[] | number[] | EmailReport[],
        webServiceFiles?: string[],
        uploadedFiles?: { name: string; data: string }[],
        itemId?: number,
        itemTypeId?: number,
        templateId?: number,
        scenario?: EmailScenario,
        manualUuid?: string,
    ) {
        const data = EmailService.getEmailData({
            subject,
            body,
            tos,
            from,
            ccs,
            bccs,
            reports,
            webServiceFiles,
            uploadedFiles,
            templateId,
        });
        if (!data.email.mail.recipient.length) {
            return { error: "Please choose at least one recipient" };
        }

        if (scenario?.isScheduled) {
            return EmailService.scheduleEmail({
                emailDocStr: JSON.stringify(data),
                manualUuid: manualUuid ?? "",
                objectId: itemId,
                objectTypeId: itemTypeId,
                scenarioId: scenario.itemId,
                templateId: templateId,
                daysFromEventStart: scenario.scheduleType === "daysFromEventStart" ? scenario.scheduleDays : undefined,
                daysFromEventEnd: scenario.scheduleType === "daysFromEventEnd" ? scenario.scheduleDays : undefined,
                daysFromNow: scenario.scheduleType === "daysFromNow" ? scenario.scheduleDays : undefined,
            });
        }

        const meta = S25Const.typeId2Meta[itemTypeId];
        const metaUrl = meta?.mailUrl || "null/mail.json";
        const params = ["async=T"];
        if (itemId && meta?.itemId) params.push(`${meta.itemId}=${itemId}`);

        const url = DataAccess.injectCaller(`/${metaUrl}?${params.join("&")}`, "EmailService.sendEmail");
        return DataAccess.post(url, data);
    }

    public static scheduleEmail(scheduledEmail: ScheduledEmail) {
        return DataAccess.post("/scheduled/email/queue.json", scheduledEmail);
    }

    public static getEmailData(data: {
        subject: string;
        body: string;
        tos: string | string[];
        from: string;
        ccs?: string | string[];
        bccs?: string | string[];
        reports?: string | string[] | number[] | EmailReport[];
        webServiceFiles?: string[];
        uploadedFiles?: { name: string; data: string }[];
        templateId?: number;
    }): EmailDoc {
        const { subject, body, tos, from, ccs, bccs, reports, webServiceFiles, uploadedFiles, templateId } = data;

        const recipient = EmailService.getRecipients({ tos, ccs, bccs });

        return {
            email: {
                mail: {
                    from: from,
                    subject: subject,
                    body: {
                        text: body,
                    },
                    recipient,
                    attachment: EmailService.getAttachments({ reports, webServiceFiles, uploadedFiles }),
                    template_id: templateId,
                },
            },
        };
    }

    public static getRecipients(data: {
        tos: string | string[];
        ccs: string | string[];
        bccs: string | string[];
    }): RecipientDoc[] {
        let tos = data.tos || [];
        let ccs = data.ccs || [];
        let bccs = data.bccs || [];

        if (typeof tos === "string") {
            tos = tos.split(",").map(S25Util.trim);
        }
        if (typeof ccs === "string") {
            ccs = ccs.split(",").map(S25Util.trim);
        }
        if (typeof bccs === "string") {
            bccs = bccs.split(",").map(S25Util.trim);
        }

        return [
            ...(tos?.filter((to) => to).map((to) => ({ to })) || []),
            ...(ccs?.filter((cc) => cc).map((cc) => ({ cc })) || []),
            ...(bccs?.filter((bcc) => bcc).map((bcc) => ({ bcc })) || []),
        ];
    }

    public static getAttachments(data: {
        reports: string | string[] | number[] | EmailReport[];
        webServiceFiles?: string[];
        uploadedFiles?: { name: string; data: string }[];
    }) {
        const { webServiceFiles, uploadedFiles } = data;

        let reports = data.reports || [];
        if (typeof reports === "string") {
            reports = reports.split(",").map(S25Util.trim);
        }

        const attachments: EmailAttachment[] = [];

        for (const report of reports || []) {
            if (typeof report === "string" || typeof report === "number") {
                attachments.push({ report });
                continue;
            }

            const isDM =
                report.template_id === Doc.scope.event.reportId ||
                report.template_id === Doc.scope.organization.reportId ||
                report.rpt_engine === "DM";
            const reportId = Number(report.rpt_id);
            const itemID = Number(report.itemID);

            if ((report.fromTemplate && report.rpt_engine === "DM") || (reportId && isDM && itemID)) {
                attachments.push({
                    report: reportId,
                    item: itemID,
                    report_run: report.params, // DM reports need report_run params
                });
            } else if (report.fromTemplate && report.isString) {
                attachments.push({ report: report.objString });
            } else if (report.rpt_engine === "JR") {
                attachments.push({
                    report: reportId,
                    result_type: report.format || 2,
                    report_run: report.params ? report.params : undefined,
                });
            } else {
                attachments.push({ report: reportId });
            }
        }

        //Used for .ics
        for (const filename of webServiceFiles || []) {
            attachments.push({ filename });
        }

        for (const file of uploadedFiles || []) {
            attachments.push({ file_upload_name: file.name, file_upload: file.data });
        }

        return attachments;
    }
}
