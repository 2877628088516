//@author: devin

import { DataAccess } from "../dataaccess/data.access";
import { Cache } from "../decorators/cache.decorator";

export class ConfigService {
    public static composeUrl(path: string, caller: string): string {
        return DataAccess.injectCaller("/config" + path, caller);
    }

    @Cache({ targetName: "ConfigService" })
    public static getSpace() {
        return DataAccess.get<ConfigResponse>(ConfigService.composeUrl("/space.json", "ConfigDao.getSpace")).then(
            function (data) {
                return data.config.item;
            },
        );
    }

    @Cache({ targetName: "ConfigService" })
    public static getEvent() {
        return DataAccess.get<ConfigResponse>(ConfigService.composeUrl("/event.json", "ConfigDao.getEvent")).then(
            function (data) {
                return data.config.item;
            },
        );
    }

    @Cache({ targetName: "ConfigService" })
    public static getResource() {
        return DataAccess.get<ConfigResponse>(ConfigService.composeUrl("/resource.json", "ConfigDao.getResource")).then(
            function (data) {
                return data.config.item;
            },
        );
    }

    @Cache({ targetName: "ConfigService" })
    public static getOrganization() {
        return DataAccess.get<ConfigResponse>(
            ConfigService.composeUrl("/organization.json", "ConfigDao.getOrganization"),
        ).then(function (data) {
            return data.config.item;
        });
    }
}

type ConfigResponse = {
    engine: "accl";
    config: {
        item: { itemId: number }[];
    };
};
