//@author: travis

import { DataAccess } from "../dataaccess/data.access";

export class EmailSettingService {
    public static getSecurityGroups() {
        return DataAccess.get(
            DataAccess.injectCaller("/emailsetting/securitygroups.json", "EmailSettingService.getSecurityGroups"),
        ).then(function (data) {
            return data && data.securitygroups;
        });
    }

    public static getGroup(group_id: number) {
        return DataAccess.get(
            DataAccess.injectCaller("/emailsetting/getgroup.json?itemId=" + group_id, "EmailSettingService.getGroup"),
        ).then(function (data) {
            return data && data.singleGroup;
        });
    }

    public static setGroup(jsonInput: any) {
        //group_ids: group_id: [], pref_name: string, pref_value: string ('none', 'daily') or int (represents minutes)
        return DataAccess.post(
            DataAccess.injectCaller("/emailsetting/setgroup.json", "EmailSettingService.setGroup"),
            jsonInput,
        ).then(function (data) {
            return data;
        });
    }

    public static setGlobalToggle(value: any) {
        return DataAccess.post(
            DataAccess.injectCaller(
                "/emailsetting/globaltoggle.json?pref_value=",
                "EmailSettingService.setGlobalToggle",
            ),
            +value,
        ).then(function (data) {
            return data;
        });
    }
}
