import { DataAccess } from "../dataaccess/data.access";
import { S25Util } from "../util/s25-util";

/**
 * Back to back binding should be completed via lynx whenever possible. This service exists onl for TCS users
 */
export class B2bBindService {
    public static async runBackToBackBind(
        eventIds: number[],
        roleId: number,
        passingMinutes: number,
        headCountVariance: number,
    ) {
        let payload = {
            b2b_info: {
                event_list: eventIds.join("+"),
                contact_role: roleId,
                max_minutes: passingMinutes,
                headcount_pct: headCountVariance,
            },
        };
        let [resp, error] = await S25Util.Maybe(DataAccess.post<B2BResults>(`/backtoback.json`, payload));
        if (error) {
            return;
        }
        return S25Util.array.forceArray(resp?.results?.status);
    }
}

export type B2BResults = {
    results: {
        status: B2BStatus[];
    };
};

export type B2BStatus = {
    item: "events" | "profiles" | "locked" | "norole" | "bound";
    count: number;
};

export type B2BSummary = {
    runNumber: number;
    events: number;
    profiles: number;
    locked: number;
    norole: number;
    bound: number;
};
