//@author: devin

import { UserprefService } from "./userpref.service";
import { S25Util } from "../util/s25-util";

export class DatetimeService {
    public static convert(date: any, format?: string) {
        return UserprefService.getTZName().then(function (tzName) {
            return S25Util.date.timezone.convert(date, tzName, format);
        });
    }

    public static currentDay() {
        return UserprefService.getTZName().then(function (tzName) {
            return S25Util.date.timezone.currentDay(tzName);
        });
    }

    public static currentHour() {
        return UserprefService.getTZName().then(function (tzName) {
            return S25Util.date.timezone.currentHour(tzName);
        });
    }

    public static nextHour() {
        return UserprefService.getTZName().then(function (tzName) {
            return S25Util.date.timezone.nextHour(tzName);
        });
    }
}
