//@author devin
import { S25Util } from "../util/s25-util";
import { Cache, Invalidate } from "../decorators/cache.decorator";
import { DataAccess } from "../dataaccess/data.access";

export interface DiscreteAttributeResponse {
    item: {
        itemId: number;
        opt: string[];
    }[];
}

export class CustomAttributeService {
    public static CustomAttributeDataMap: any = {
        1: { url: "evatrb.json", name: "events", key: "event_id" },
        4: { url: "rmat.json", name: "locations", key: "room_id" },
        2: { url: "orgat.json", name: "organizations", key: "organization_id" },
        6: { url: "resat.json", name: "resources", key: "resource_id" },
        3: { url: "conat.json", name: "contacts", key: "contact_id" },
    };

    public static toCustAttrObj(s25CustAttr: any) {
        let attrType = s25CustAttr.attribute_type;

        //for objects, extract name, else fall back to attribute value for itemName
        let itemName =
            S25Util.propertyGetVal(s25CustAttr, "attribute_contact_name") ||
            S25Util.propertyGetVal(s25CustAttr, "attribute_space_name") ||
            S25Util.propertyGetVal(s25CustAttr, "attribute_resource_name") ||
            S25Util.propertyGetVal(s25CustAttr, "attribute_organization_name") ||
            S25Util.propertyGetVal(s25CustAttr, "attribute_image_name") ||
            s25CustAttr.attribute_value;

        return {
            custAttrId: parseInt(s25CustAttr.attribute_id),
            //itemId is itemId of referenced object if attr type is an object...else it is just the cust attr id
            itemId: parseInt(
                [1, 4, 6, 2, 3].indexOf(parseInt(attrType)) > -1 || ["I"].indexOf(attrType) > -1
                    ? s25CustAttr.attribute_value
                    : s25CustAttr.attribute_id,
            ),
            imageId: ["I"].indexOf(attrType) > -1 ? s25CustAttr.attribute_value : null,
            itemName: itemName,
            itemTypeId: attrType,
            itemLabel: s25CustAttr.attribute_name,

            multi_val: S25Util.toBool(s25CustAttr.multi_val),
        };
    }

    @Cache({ immutable: true, targetName: "CustomAttributeService" })
    public static getCustomAttributes(type: string | number, includeInactive?: boolean) {
        return DataAccess.get(
            DataAccess.injectCaller(
                "/" + CustomAttributeService.CustomAttributeDataMap[type].url + (!includeInactive ? "?active=T" : ""),
                "CustomAttributeService.getCustomAttributes",
            ),
        ).then(function (data: any) {
            return S25Util.prettifyJson(
                data,
                {
                    event_custom_attributes: "custom_attributes",
                    space_custom_attributes: "custom_attributes",
                    resource_custom_attributes: "custom_attributes",
                    organization_custom_attributes: "custom_attributes",
                    contact_custom_attributes: "custom_attributes",
                    attribute_type: "cust_atrb_type",
                    attribute_name: "cust_atrb_name",
                    attribute_id: "cust_atrb_id",
                    attribute_type_name: "cust_atrb_type_name",
                },
                { attribute: true },
            );
        });
    }

    //convert event details custom attribute to normal API custom attribute for generic use
    //s25 objects:
    //are converted to their attribute nodes (attribute_organization, etc)
    //the ev details itemId is the itemId of the object NOT the custom attr id as with others
    //the itemId is the attribute value NOT the itemName as with others
    public static toCustomAttributeNode(evDetailCustAttr: any) {
        let node: any = {};
        node.attribute_id = evDetailCustAttr.custAttrId || evDetailCustAttr.itemId; //set attribute id
        node.attribute_name = evDetailCustAttr.itemLabel; //set attribute name

        let itemName = evDetailCustAttr.itemName + "";

        //handle s25 objects as attributes -- ev details has the itemTypeId as the obj type
        let itemIdInt = parseInt(evDetailCustAttr.itemId);
        let itemTypeIdInt = parseInt(evDetailCustAttr.itemTypeId);
        let itemObjMap: any = {
            2: {
                itemObjProp: "attribute_organization",
                itemIdProp: "attribute_organization_id",
                itemNameProp: "attribute_organization_name",
            },
            3: {
                itemObjProp: "attribute_contact",
                itemIdProp: "attribute_contact_id",
                itemNameProp: "attribute_contact_name",
            },
            4: {
                itemObjProp: "attribute_space",
                itemIdProp: "attribute_space_id",
                itemNameProp: "attribute_space_name",
            },
            6: {
                itemObjProp: "attribute_resource",
                itemIdProp: "attribute_resource_id",
                itemNameProp: "attribute_resource_name",
            },
        };

        if ([2, 3, 4, 6].indexOf(itemTypeIdInt) > -1) {
            node.attribute_type = itemTypeIdInt;
            node.attribute_value = itemIdInt;
            node[itemObjMap[itemTypeIdInt].itemObjProp] = {}; //set obj attribute node, eg attribute_organization
            var obj = node[itemObjMap[itemTypeIdInt].itemObjProp]; //get that created node
            obj[itemObjMap[itemTypeIdInt].itemIdProp] = itemIdInt; //set that node's id attribute, eg attribute_organization_id
            obj[itemObjMap[itemTypeIdInt].itemNameProp] = itemName; //and set that node's name attribute, eg attribute_organization_name
        } else {
            //handle non object type attributes
            if ([4311, 4312].indexOf(evDetailCustAttr.itemTypeId) > -1) {
                node.attribute_type = "I";
            } else if (evDetailCustAttr.itemTypeId === 162) {
                node.attribute_type = "R";
            } else {
                node.attribute_type = evDetailCustAttr.itemTypeId;
            }

            if (evDetailCustAttr.itemTypeId === "B") {
                node.attribute_value = S25Util.toBool(evDetailCustAttr.itemName) ? "T" : "F";
            } else {
                node.attribute_value = evDetailCustAttr.itemName;
            }
        }

        if (evDetailCustAttr.itemDesc) {
            node.attribute_image = { attribute_image_name: evDetailCustAttr.itemDesc };
        }

        return node;
    }

    //imageId, imageName, imageDesc, imageFileName only used for an image attr type (I) where value is the base64 of an image
    public static putEventCustomAttribute(
        eventIds: number[],
        custAttrId: number,
        custAtrbType: string | number,
        value: any,
    ) {
        eventIds = S25Util.array.forceArray(eventIds);
        var imageId, imageName, imageDesc, imageFileName, imageTypeId;
        //handle image (I) custom attrs: if imageId provided, user is updating event cust atrb to point to existing image
        //so the PUT service just needs the new imageId to use
        //else, it must be a new image and thus the PUT service needs the new image name, desc, filename, and actual base64 data
        if (custAtrbType === "I" && value) {
            if (value.imageId) {
                imageId = value.imageId;
                value = undefined;
            } else {
                imageName = value.imageName;
                imageDesc = value.imageDesc;
                imageFileName = value.imageFileName;
                imageTypeId = value.imageTypeId;
                value = value.imageData;
            }
        }

        return DataAccess.put(
            DataAccess.injectCaller(
                "/event/customattrs/update.json?itemId=" +
                    custAttrId +
                    "&itemTypeId=" +
                    custAtrbType +
                    "&image_id=" +
                    (imageId || ""),
                "CustomAttributeService.putEventCustomAttribute",
            ),
            {
                root: {
                    events: eventIds.map(function (id) {
                        return { event_id: id };
                    }),
                    itemName: value,
                    imageName: imageName,
                    imageDesc: imageDesc,
                    imageFileName: imageFileName,
                    image_type: imageTypeId,
                },
            },
        );
    }

    public static delEventCustomAttribute(eventIds: number[], custAttrId: number) {
        eventIds = S25Util.array.forceArray(eventIds);
        return DataAccess.delete(
            DataAccess.injectCaller(
                "/event/customattrs/remove.json?itemId=" + custAttrId,
                "CustomAttributeService.delEventCustomAttribute",
            ),
            {
                root: {
                    events: eventIds.map(function (id) {
                        return { event_id: id };
                    }),
                },
            },
        );
    }

    public static getNewCustomAttributeId(itemTypeId: number) {
        return DataAccess.post("/" + CustomAttributeService.CustomAttributeDataMap[itemTypeId].url).then(
            function (data) {
                return S25Util.propertyGetVal(data, "attribute_id");
            },
        );
    }

    @Invalidate({ methodName: "CustomAttributeService.getCustomAttributes" })
    public static createCustomAttribute(itemTypeId: number, name: string, type: string | number, isActive: boolean) {
        return CustomAttributeService.getNewCustomAttributeId(itemTypeId).then(function (attrId: number) {
            name = name || "New_" + attrId;
            isActive = S25Util.coalesce(isActive, true);
            return CustomAttributeService.updateCustomAttribute(
                itemTypeId,
                attrId,
                name,
                type,
                isActive,
                1,
                "new",
            ).then(function () {
                return attrId;
            });
        });
    }

    @Invalidate({ methodName: "CustomAttributeService.getCustomAttributes" })
    public static deleteCustomAttribute(itemTypeId: number, attrId: number, name?: string, type?: string | number) {
        return DataAccess.post(
            "/" + CustomAttributeService.CustomAttributeDataMap[itemTypeId].url,
            CustomAttributeService.getCustomAttributeJson(itemTypeId, {
                attribute: {
                    status: "del",
                    attribute_id: attrId,
                    attribute_name: name,
                    attribute_type: type,
                },
            }),
        );
    }

    public static getCustomAttributeJson(itemTypeId: number, json: any): any {
        let map: any = {
            1: { event_custom_attributes: json },
            2: { organization_custom_attributes: json },
            4: { space_custom_attributes: json },
            6: { resource_custom_attributes: json },
            3: { contact_custom_attributes: json },
        };
        return map[itemTypeId];
    }

    @Invalidate({ methodName: "CustomAttributeService.getCustomAttributes" })
    public static updateCustomAttribute(
        itemTypeId: number,
        attrId: number,
        name?: string,
        type?: string | number,
        isActive?: boolean,
        sortOrder?: number,
        mod?: string,
    ) {
        let payload: any = {
            status: mod || "mod",
            attribute_id: attrId,
        };

        if (S25Util.isDefined(name)) {
            payload.attribute_name = name;
        }

        if (S25Util.isDefined(type)) {
            payload.attribute_type = type;
        }

        if (S25Util.isDefined(isActive)) {
            payload.defn_state = S25Util.toBool(isActive) ? 1 : 0;
        }

        if (S25Util.isDefined(sortOrder)) {
            payload.sort_order = sortOrder;
        }

        return DataAccess.post(
            "/" + CustomAttributeService.CustomAttributeDataMap[itemTypeId].url,
            CustomAttributeService.getCustomAttributeJson(itemTypeId, {
                attribute: payload,
            }),
        );
    }

    @Cache({ immutable: true, targetName: "CustomAttributeService" })
    public static getAllDiscreteOptions(): Promise<DiscreteAttributeResponse["item"]> {
        return DataAccess.get("/custom/attribute/options.json").then(function (data) {
            let retVal = (data && data.item) || [];
            //force the op values to be strings ANG-4444
            retVal.forEach((item: { itemId: number; opt: string[] }) => {
                item.opt = item.opt?.map((op: string) => {
                    return op.toString();
                });
            });
            return retVal;
        });
    }

    public static getDiscreteOptions(attrId: number): Promise<string[]> {
        return CustomAttributeService.getAllDiscreteOptions().then(function (items) {
            var item = attrId && S25Util.propertyGetParentWithChildValue(items, "itemId", attrId);
            return (item && item.opt) || [];
        });
    }

    @Invalidate({ methodName: "CustomAttributeService.getAllDiscreteOptions" })
    public static putDiscreteOptions(attrId: number, options: any) {
        return DataAccess.put("/custom/attribute/options.json?itemId=" + attrId, {
            root: {
                opt: options || [],
            },
        });
    }

    @Invalidate({ methodName: "CustomAttributeService.getCustomAttributes" })
    public static putSortOrder(itemTypId: number, sortOrderPayload: any) {
        return DataAccess.put("/custom/attribute/sort.json?itemTypeId=" + itemTypId, {
            root: {
                sortOrder: sortOrderPayload || [],
            },
        });
    }

    public static editCustomAttribute(
        itemIds: number[],
        custAttrId: number,
        custAtrbType: string | number,
        value: any,
        typeId: number,
    ) {
        let url: any = "";
        switch (typeId) {
            case 1:
                url = "/event/customattrs/update.json?itemId=";
                break;
            case 4:
                url = "/space/custom/attribute.json?itemId=";
                break;
            case 2:
                url = "/organization/custom/attribute.json?itemId=";
                break;
            case 6:
                url = "/resource/custom/attribute.json?itemId=";
                break;
        }
        itemIds = S25Util.array.forceArray(itemIds);
        var imageId, imageName, imageDesc, imageFileName;

        if (custAtrbType === "I" && value) {
            if (value.imageId) {
                imageId = value.imageId;
                value = undefined;
            } else {
                imageName = value.imageName;
                imageDesc = value.imageDesc;
                imageFileName = value.imageFileName;
                value = value.imageData;
            }
        }

        return DataAccess.put(
            DataAccess.injectCaller(
                url + custAttrId + "&itemTypeId=" + custAtrbType + "&image_id=" + (imageId || ""),
                "CustomAttributeService.editCustomAttribute",
            ),
            {
                root: {
                    [CustomAttributeService.CustomAttributeDataMap[typeId].name]: itemIds.map(function (id) {
                        return { [CustomAttributeService.CustomAttributeDataMap[typeId].key]: id };
                    }),
                    itemName: value,
                    imageName: imageName,
                    imageDesc: imageDesc,
                    imageFileName: imageFileName,
                },
            },
        );
    }

    public static delCustomAttribute(itemIds: number[], custAttrId: number, typeId: number) {
        let url: any = "";
        switch (typeId) {
            case 1:
                url = "/event/customattrs/remove.json?itemId=";
                break;
            case 4:
                url = "/space/custom/attribute.json?itemId=";
                break;
            case 2:
                url = "/organization/custom/attribute.json?itemId=";
                break;
            case 6:
                url = "/resource/custom/attribute.json?itemId=";
                break;
        }
        itemIds = S25Util.array.forceArray(itemIds);
        return DataAccess.delete(
            DataAccess.injectCaller(url + custAttrId, "CustomAttributeService.delCustomAttribute"),
            {
                root: {
                    [CustomAttributeService.CustomAttributeDataMap[typeId].name]: itemIds.map(function (id) {
                        return { [CustomAttributeService.CustomAttributeDataMap[typeId].key]: id };
                    }),
                },
            },
        );
    }

    /*
    gets all distinct custom attribute values
    this service only supports string type custom attributes
     */
    public static getDistinctValues(itemTypeId: number, atrbId: number): Promise<[val: string, itemIds: number[]]> {
        return DataAccess.get(
            DataAccess.injectCaller(
                `/custom/attribute/distinct/values.json?attribute_id=${atrbId}`,
                "CustomAttributeService.getDistinctValues",
            ),
        ).then((resp) => {
            return resp?.root;
        });
    }
}
